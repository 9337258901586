import React, {useState} from 'react'
import {Link} from 'react-router-dom';

import Navbar from '../../component/Navbar/navbar'
import Footer from '../../component/Footer/footer';

import {MdKeyboardArrowRight, FaArrowRight, BsCheckCircle, FaArrowDown} from "../../assets/icons/icons"
import {accordionData, pricingTableData} from '../../data/dataTwo';
import shree_logo from "../../assets/images/igchamp/select-package.png";
import google_logo from "../../assets/images/igchamp/put-info.png";
import lenovo_logo from "../../assets/images/igchamp/payment.png";
import circle_logo from "../../assets/images/igchamp/deliver-order.png";
import classic02 from "../../assets/images/igchamp/buy-tiktok-views.png";
import {Helmet} from "react-helmet";

export default function PageTerms() {
    const [activeIndex, setActiveIndex] = useState(0);
    const [pricingTable, setpricingTable] = useState(pricingTableData.filter((element) => {
        return element.cat === 22;
    }))
    const toggleAccordion = (index) => {
        if (activeIndex === index) {
            setActiveIndex(0);
        } else {
            setActiveIndex(index);
        }
    }
    let accordionData1 = accordionData.filter((x) => x.id < 5);
    let accordionData2 = accordionData.filter((x) => x.id > 4)
    return (
        <>
            <Helmet>
                <title>Buy TikTok Views - 100% Real and Instant - Starting From $1</title>
                <meta name="description"
                      content="Buy real and authentic TikTok views from IGChamp and let your content reach a wide audience, eventually building engagement on your account. "/>
                <meta property="og:title" content="Buy TikTok Views - 100% Real and Instant - Starting From $1"/>
                <meta property="og:description"
                      content="Buy real and authentic TikTok views from IGChamp and let your content reach a wide audience, eventually building engagement on your account. "/>
                <meta name="keywords" content="Buy Tiktok Views, real and Instant "/>
                <meta property="og:image" content={classic02}/>
                <meta property="og:url" content="/buy-tiktok-views"/>
                <link rel="canonical" href="https://igchamp.com/buy-tiktok-views"/>
            </Helmet>
            <Navbar/>

            <section className="relative table w-full py-32 lg:py-40 bg-gray-50 dark:bg-slate-800">
                <div className="container relative">
                    <div className="grid grid-cols-1 text-center mt-10">
                        <h1 className="text-3xl leading-normal font-semibold">Buy TikTok Views to Maximize Your
                            Reach</h1>
                        <p>Maximize your reach on TikTok with IG Champ. Buy
                            real and authentic TikTok views from us and let your content reach a wide audience,
                            eventually building engagement on your account</p>
                    </div>
                </div>
                <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
                    <ul className="tracking-[0.5px] mb-0 inline-flex space-x-1">
                        <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out hover:text-indigo-600">
                            <Link to="/">Home</Link></li>
                        <li className="inline-block text-base mx-0.5 ltr:rotate-0 rtl:rotate-180"><MdKeyboardArrowRight
                            className="text-xl"/></li>
                        <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out hover:text-indigo-600">
                            <Link>TikTok</Link></li>
                        <li className="inline-block text-base mx-0.5 ltr:rotate-0 rtl:rotate-180"><MdKeyboardArrowRight
                            className="text-xl"/></li>
                        <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-indigo-600"
                            aria-current="page">Buy Tiktok Views
                        </li>
                    </ul>
                </div>
            </section>

            <div className="relative">
                <div
                    className="shape absolute sm:-bottom-px -bottom-[2px] start-0 end-0 overflow-hidden z-1 text-white dark:text-slate-900">
                    <svg className="w-full h-auto scale-[2.0] origin-top" viewBox="0 0 2880 48" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>

            <section className="relative md:py-24 py-16">
                <div className="container relative">
                    <div className="grid grid-cols-1 pb-8 text-center">
                        <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">Choose
                            Pricing
                            Plan</h3>

                        <p className="text-slate-400 max-w-xl mx-auto">Your account should be public!</p>
                    </div>
                    <div id="StarterContent" className="mt-6">
                        <div className="grid lg:grid-cols-4 md:grid-cols-2 mt-8 gap-[30px]">
                            {pricingTable.map((pricing, index) => {
                                return (
                                    <div
                                        className="group border-b-[3px] dark:border-gray-700 p-6 py-8 hover:border-indigo-600 dark:hover:border-indigo-600 transition-all duration-500 ease-in-out hover:scale-105 relative overflow-hidden shadow dark:shadow-gray-800 rounded-md bg-gray-50 dark:bg-slate-800 hover:bg-white dark:hover:bg-slate-900 h-fit">
                                        <h6 className="font-bold uppercase mb-5 text-indigo-600">{pricing.name}</h6>

                                        <div className="flex mb-5">
                                            <span className="text-xl font-semibold">$</span>
                                            <span className="price text-4xl font-semibold mb-0">{pricing.price}</span>
                                            <span className="text-xl font-semibold self-end mb-1"></span>
                                        </div>

                                        <ul className="list-none text-slate-400">
                                            <li className="mb-1 flex items-center" key={"a" + pricing.id}>
                                                <BsCheckCircle className="text-indigo-600 text-base me-2"/> 5 Minutes to
                                                24Hours
                                            </li>
                                            <li className="mb-1 flex items-center" key={"b" + pricing.id}>
                                                <BsCheckCircle className="text-indigo-600 text-base me-2"/> Safe &
                                                Secure
                                            </li>
                                            <li className="mb-1 flex items-center" key={"c" + pricing.id}>
                                                <BsCheckCircle className="text-indigo-600 text-base me-2"/> Real and
                                                Active
                                            </li>
                                            <li className="mb-1 flex items-center" key={"d" + pricing.id}>
                                                <BsCheckCircle className="text-indigo-600 text-base me-2"/> Money-Back
                                                Guarantee
                                            </li>
                                        </ul>
                                        <a
                                            className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md mt-5"
                                            href={pricing.link}>Buy
                                            Now</a>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </section>
            <section className="relative md:py-24 py-16 bg-gray-50 dark:bg-slate-800">
                <div className="container relative">
                    <div className="grid grid-cols-1 pb-8 text-center">
                        <h6 className="text-indigo-600 text-sm font-bold uppercase mb-2">Boost TikTok</h6>
                        <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">How to
                            Place Order!
                        </h3>
                    </div>

                    <div className="grid grid-cols-1 mt-8">
                        <div
                            className="relative after:content-[''] after:absolute after:top-0 md:after:end-0 md:after:start-0 after:w-px after:h-full md:after:m-auto after:border-s-2 after:border-dashed after:border-gray-200 dark:after:border-gray-700 ms-3 md:ms-0">
                            <div
                                className="ms-8 md:ms-0 relative after:content-[''] after:absolute after:top-[9px] after:rounded-full after:z-10 after:w-2.5 after:h-2.5 after:bg-indigo-600 md:after:mx-auto md:after:end-0 md:after:start-0 after:-start-9 before:content-[''] before:absolute md:before:mx-auto md:before:end-0 md:before:start-0 before:-start-11 before:rounded-full before:z-10 before:border-2 before:border-dashed before:border-gray-200 dark:before:border-gray-700 before:top-0 before:w-7 before:h-7 before:bg-white dark:before:bg-slate-900">
                                <div className="grid md:grid-cols-2">
                                    <div className="md:text-end md:me-8 relative">
                                        <img src={shree_logo} className="md:ms-auto" alt=""/>
                                    </div>

                                    <div className="ltr:float-left rtl:float-right text-start ms-8 mt-6 md:mt-0">
                                        <h5 className="title mb-1 font-semibold">Select Package</h5>
                                        <p className="mt-3 mb-0 text-slate-400">Selecting the perfect package to buy
                                            TikTok views with IG Champ is as easy as ABC. We provide a variety of view
                                            packages to meet diverse needs and budgets. Whether you're new to TikTok or
                                            looking to expand an already established profile, we have the right
                                            solution. Choose the number of views that matches your goals and watch your
                                            TikTok videos being noticed. At IG Champ, our carefully designed packages
                                            deliver maximum impact, ensuring your profile gains the visibility and
                                            engagement it deserves.</p>
                                    </div>
                                </div>
                            </div>

                            <div
                                className="mt-12 ms-8 md:ms-0 relative after:content-[''] after:absolute after:top-[9px] after:rounded-full after:z-10 after:w-2.5 after:h-2.5 after:bg-indigo-600 md:after:mx-auto md:after:end-0 md:after:start-0 after:-start-9 before:content-[''] before:absolute md:before:mx-auto md:before:end-0 md:before:start-0 before:-start-11 before:rounded-full before:z-10 before:border-2 before:border-dashed before:border-gray-200 dark:before:border-gray-700 before:top-0 before:w-7 before:h-7 before:bg-white dark:before:bg-slate-900">
                                <div className="grid md:grid-cols-2">
                                    <div className="text-start ms-8 relative md:order-2">
                                        <img src={google_logo} className="md:me-auto" alt=""/>
                                    </div>

                                    <div
                                        className="ltr:float-left rtl:float-right md:text-end md:me-8 mt-6 md:mt-0 md:order-1">
                                        <h5 className="title mb-1 font-semibold">Put Desired Info</h5>
                                        <p className="mt-3 mb-0 text-slate-400">Submitting your information to buy
                                            TikTok views with IG Champ is easy and secure. Just enter your TikTok
                                            username and select your preferred package. You won’t be asked to provide us
                                            with passwords, as your account’s safety and privacy are our top priority.
                                            Our user-friendly interface makes the purchase process quick and simple,
                                            needing only a few clicks. After submitting your information, our team will
                                            promptly deliver the views to your profile. With IG Champ, you can trust
                                            that your details are handled securely and that your TikTok growth is
                                            expertly managed.</p>
                                    </div>
                                </div>
                            </div>

                            <div
                                className="mt-12 ms-8 md:ms-0 relative after:content-[''] after:absolute after:top-[9px] after:rounded-full after:z-10 after:w-2.5 after:h-2.5 after:bg-indigo-600 md:after:mx-auto md:after:end-0 md:after:start-0 after:-start-9 before:content-[''] before:absolute md:before:mx-auto md:before:end-0 md:before:start-0 before:-start-11 before:rounded-full before:z-10 before:border-2 before:border-dashed before:border-gray-200 dark:before:border-gray-700 before:top-0 before:w-7 before:h-7 before:bg-white dark:before:bg-slate-900">
                                <div className="grid md:grid-cols-2">
                                    <div className="md:text-end md:me-8 relative">
                                        <img src={lenovo_logo} className="md:ms-auto" alt=""/>
                                    </div>

                                    <div className="ltr:float-left rtl:float-right text-start ms-8 mt-6 md:mt-0">
                                        <h5 className="title mb-1 font-semibold">Pay for your order</h5>
                                        <p className="mt-3 mb-0 text-slate-400">Finalizing your order to buy TikTok
                                            views with IG Champ is seamless. After selecting your desired package,
                                            proceed to our secure payment gateway to complete the transaction. We accept
                                            various payment methods to accommodate your preferences, ensuring a smooth
                                            and convenient checkout experience. Rest assured, all your payment details
                                            are protected with advanced encryption technology, safeguarding your
                                            information. Once the payment is confirmed, our team will immediately start
                                            delivering the views to your TikTok profile, helping you efficiently enhance
                                            your social media presence. With IG Champ, you can trust that your payment
                                            process is secure and straightforward.</p>
                                    </div>
                                </div>
                            </div>

                            <div
                                className="mt-12 ms-8 md:ms-0 relative after:content-[''] after:absolute after:top-[9px] after:rounded-full after:z-10 after:w-2.5 after:h-2.5 after:bg-indigo-600 md:after:mx-auto md:after:end-0 md:after:start-0 after:-start-9 before:content-[''] before:absolute md:before:mx-auto md:before:end-0 md:before:start-0 before:-start-11 before:rounded-full before:z-10 before:border-2 before:border-dashed before:border-gray-200 dark:before:border-gray-700 before:top-0 before:w-7 before:h-7 before:bg-white dark:before:bg-slate-900">
                                <div className="grid md:grid-cols-2">
                                    <div className="text-start ms-8 relative md:order-2">
                                        <img src={circle_logo} className="md:me-auto" alt=""/>
                                    </div>

                                    <div
                                        className="ltr:float-left rtl:float-right md:text-end md:me-8 mt-6 md:mt-0 md:order-1">
                                        <h5 className="title mb-1 font-semibold">Order Delivered</h5>
                                        <p className="mt-3 mb-0 text-slate-400">After completing your purchase with IG
                                            Champ, you'll quickly begin to see the results. Your TikTok views will
                                            increase almost immediately, boosting your content's visibility and
                                            engagement. As your videos gain more views, they'll be more likely to appear
                                            on the "For You" page, attracting a broader audience. This surge in views
                                            can lead to higher interaction rates, more followers, and enhanced
                                            credibility on the platform. With IG Champ, you can monitor your progress
                                            and witness the positive impact on your TikTok profile, confident in the
                                            effectiveness of our service.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="relative overflow-hidden pb-32 bg-gray-50 dark:bg-slate-800">
                <div className="container grid md:grid-cols-2 grid-cols-1 items-center mt-16 gap-[30px]">
                    <div className="relative wow animate__animated animate__fadeInLeft" data-wow-delay=".3s">
                        <img src={classic02} className="rounded-lg dark:shadow-gray-800 mx-auto"
                             alt="Buy TikTok Views"/>
                        <div
                            className="overflow-hidden absolute lg:h-[400px] h-[320px] lg:w-[400px] w-[320px] bg-indigo-600/5 bottom-0 start-0 rotate-45 -z-1 rounded-3xl"></div>
                    </div>
                    <div className="lg:ms-8 wow animate__animated animate__fadeInRight" data-wow-delay=".3s">
                        <h2 className="mb-4 text-2xl leading-normal font-medium">Skyrocket Your TikTok Views with IG
                            Champ's Expert Service</h2>
                        <p className="text-slate-400">Boost your TikTok presence with IG Champ's expert service,
                            designed to skyrocket your views and elevate your content. Our targeted strategies ensure
                            your videos gain the attention they deserve, increasing visibility and engagement across the
                            platform. By purchasing views from IG Champ, you can leverage our expertise to reach a
                            broader audience and enhance your profile's credibility. Whether you're an influencer, a
                            brand, or a newcomer, our tailored packages provide the perfect solution to amplify your
                            TikTok success. Trust IG Champ to deliver fast, reliable, impactful results, transforming
                            your TikTok experience.</p>
                    </div>
                </div>
            </section>
            <section className="relative md:py-24 py-16">
                <div className="container relative">
                    <div className="md:flex justify-center">
                        <div className="">
                            <div className="p-6 bg-white dark:bg-slate-900 shadow dark:shadow-gray-800 rounded-md">
                                <h2 className="text-xl font-semibold mb-4">Buy TikTok views to Go viral!</h2>
                                <p className="text-slate-400">Buying TikTok views can significantly enhance your reach
                                    on the platform. With more views, your content will likely be featured on the "For
                                    You" page, attracting a larger audience and increasing engagement. IG Champ offers a
                                    reliable and effective service to boost your TikTok views, helping you get noticed
                                    and grow your presence and influence in the market. It’s time to benefit from our
                                    expert solutions to elevate your TikTok strategy and achieve your social media
                                    goals.</p>

                                <h4 className="text-xl font-semibold mb-4 mt-8">Services That Suit Your Budget</h4>
                                <p className="text-slate-400">At IG Champ, we understand that every budget is unique and
                                    limited, so we offer a range of affordable options to buy TikTok views. Our services
                                    are designed to provide maximum value without compromising quality, ensuring you can
                                    boost your TikTok presence without breaking the bank. Whether you're an influencer,
                                    a business, or just starting on TikTok, we have packages tailored to suit your
                                    financial needs and expectations. </p>
                                <h4 className="text-xl font-semibold mb-4 mt-8">Reaching New and Targeted Audiences</h4>
                                <p className="text-slate-400">With IG Champ, reaching new and targeted audiences on
                                    TikTok has never been easier. Firstly, our services are designed to enhance your
                                    content's visibility, ensuring it reaches the right viewers. Additionally,
                                    purchasing TikTok views increases the likelihood of your videos appearing on the
                                    coveted "For You" page, attracting a more diverse audience. Furthermore, our
                                    strategic approach targets specific demographics relevant to your niche, helping you
                                    connect with users who are genuinely interested in your content. Ultimately, this
                                    targeted exposure boosts your engagement and fosters a loyal and active follower
                                    base.</p>
                                <h4 className="text-xl font-semibold mb-4 mt-8">100% Authentic</h4>
                                <p className="text-slate-400">At IG Champ, we pride ourselves on providing 100%
                                    authentic TikTok views. First and foremost, our service is designed to enhance your
                                    profile with genuine views, ensuring real engagement and interaction. Moreover, we
                                    prioritize the authenticity of our views to help you build a credible and
                                    trustworthy presence on TikTok. In addition, our commitment to quality means that
                                    every view you purchase is from a real user, avoiding the risks associated with fake
                                    or bot-generated views. As a result, you can confidently grow your TikTok following,
                                    knowing that your engagement is legitimate and effective. With IG Champ, you receive
                                    a service that upholds the highest standards of authenticity and integrity.</p>
                                <h4 className="text-xl font-semibold mb-4 mt-8">Instant Results</h4>
                                <p className="text-slate-400">Experience instant results with IG Champ's TikTok views
                                    service. Once you purchase views from us, you'll notice a rapid increase in your
                                    video's visibility and engagement. Our efficient delivery system ensures that views
                                    are added promptly to your content, instantly boosting its reach and potential
                                    audience. This immediate impact enhances your video's performance on TikTok and
                                    accelerates your overall growth on the platform. With IG Champ, you can expect quick
                                    and measurable results that propel your TikTok presence to new heights, ensuring
                                    your content receives the attention it deserves right from the start.</p>
                                <h4 className="text-xl font-semibold mb-4 mt-8">Increased Engagement</h4>
                                <p className="text-slate-400">YWith IG Champ's TikTok views service, you can
                                    significantly boost engagement on your videos. By purchasing views, you increase the
                                    likelihood of your content appearing on users' feeds and the "For You" page,
                                    attracting more likes, comments, and shares. This heightened engagement enhances
                                    your visibility and builds a stronger connection with your audience. As more users
                                    interact with your content, it creates a positive feedback loop, further increasing
                                    your reach and influence on TikTok. IG Champ's strategic approach ensures that every
                                    view contributes to meaningful engagement, helping you build a vibrant community
                                    around your TikTok profile.</p>
                                <h4 className="text-xl font-semibold mb-4 mt-8">TikTok Compliant </h4>
                                <p className="text-slate-400">Our service adheres strictly to TikTok's guidelines and
                                    policies. Our approach ensures that all views delivered comply with TikTok's terms
                                    of service, safeguarding your account from any risks or penalties. We prioritize
                                    ethical practices and transparency in our operations, guaranteeing that the views
                                    you purchase are authentic and from real users. This commitment to compliance not
                                    only protects your TikTok profile but also ensures sustainable growth and
                                    credibility on the platform. Trust IG Champ to provide a service that respects
                                    TikTok's regulations while effectively enhancing your visibility and engagement.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="relative md:py-8 py-16">
                <div className="container relative">
                    <div className="md:flex justify-center">
                        <div className="">
                            <div className="p-6 bg-white dark:bg-slate-900 shadow dark:shadow-gray-800 rounded-md">
                                <div className="md:mt-24 mt-16">
                                    <div className="grid grid-cols-1 pb-8 text-center">
                                        <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">Got
                                            Questions? Get the Answers. </h3>
                                    </div>

                                    <div id="accordion-collapse" data-accordion="collapse"
                                         className="grid md:grid-cols-2 grid-cols-1 mt-8 md:gap-[30px]">
                                        <div>
                                            <div key="11"
                                                 className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4">
                                                <h4 className="text-base font-semibold"
                                                    id="accordion-collapse-heading-1">
                                                    <button type="button" onClick={() => toggleAccordion(11)}
                                                            className={`flex justify-between items-center p-5 w-full font-medium text-start ${activeIndex === 11 ? 'bg-gray-50 dark:bg-slate-800 text-indigo-600' : ''}`}
                                                            data-accordion-target="#accordion-collapse-body-1"
                                                            aria-expanded="true"
                                                            aria-controls="accordion-collapse-body-1">
                                                        <span>Why do buying views on TikTok make a difference?</span>
                                                        <FaArrowDown
                                                            className={`${activeIndex === 11 ? "rotate-180" : "rotate-270"} w-4 h-4 shrink-01`}></FaArrowDown>
                                                    </button>
                                                </h4>
                                                <div className={activeIndex === 11 ? "" : "hidden"}>
                                                    <div className="p-5">
                                                        <p className="text-slate-400 dark:text-gray-400">Buying views on
                                                            TikTok can make a significant difference in several ways.
                                                            Firstly, it enhances the visibility of your videos by
                                                            increasing their view count, making them more likely to
                                                            appear on users' feeds and the "For You" page. This
                                                            increased exposure can attract more organic views, likes,
                                                            comments, and shares, thereby boosting overall engagement.
                                                            Moreover, higher view counts can enhance your credibility
                                                            and authority on TikTok, making your content appear more
                                                            popular and trustworthy to new viewers. </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            <div key="12"
                                                 className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4">
                                                <h4 className="text-base font-semibold"
                                                    id="accordion-collapse-heading-1">
                                                    <button type="button" onClick={() => toggleAccordion(12)}
                                                            className={`flex justify-between items-center p-5 w-full font-medium text-start ${activeIndex === 12 ? 'bg-gray-50 dark:bg-slate-800 text-indigo-600' : ''}`}
                                                            data-accordion-target="#accordion-collapse-body-1"
                                                            aria-expanded="true"
                                                            aria-controls="accordion-collapse-body-1">
                                                        <span>Why do real views matter?</span>
                                                        <FaArrowDown
                                                            className={`${activeIndex === 12 ? "rotate-180" : "rotate-270"} w-4 h-4 shrink-01`}></FaArrowDown>
                                                    </button>
                                                </h4>
                                                <div className={activeIndex === 12 ? "" : "hidden"}>
                                                    <div className="p-5">
                                                        <p className="text-slate-400 dark:text-gray-400">Real views on
                                                            TikTok matter because they contribute to genuine engagement
                                                            and interaction with your content. Unlike fake or
                                                            bot-generated views, real views come from actual users who
                                                            are interested in your videos, which can lead to meaningful
                                                            interactions such as likes, comments, and shares. These
                                                            interactions signal to TikTok's algorithm that your content
                                                            is valuable and engaging and help build a loyal and active
                                                            community around your profile. Real views also contribute to
                                                            your credibility and reputation on TikTok, as they
                                                            demonstrate genuine interest and support from real
                                                            people.</p>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                        <div>
                                            <div key="13"
                                                 className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4">
                                                <h4 className="text-base font-semibold"
                                                    id="accordion-collapse-heading-1">
                                                    <button type="button" onClick={() => toggleAccordion(13)}
                                                            className={`flex justify-between items-center p-5 w-full font-medium text-start ${activeIndex === 13 ? 'bg-gray-50 dark:bg-slate-800 text-indigo-600' : ''}`}
                                                            data-accordion-target="#accordion-collapse-body-1"
                                                            aria-expanded="true"
                                                            aria-controls="accordion-collapse-body-1">
                                                        <span>Does IG Champ deliver “real” views?</span>
                                                        <FaArrowDown
                                                            className={`${activeIndex === 13 ? "rotate-180" : "rotate-270"} w-4 h-4 shrink-01`}></FaArrowDown>
                                                    </button>
                                                </h4>
                                                <div className={activeIndex === 13 ? "" : "hidden"}>
                                                    <div className="p-5">
                                                        <p className="text-slate-400 dark:text-gray-400">IG Champ prides
                                                            itself on delivering authentic and real TikTok views. We
                                                            ensure that the views you purchase come from genuine TikTok
                                                            users who are actively engaging with content on the
                                                            platform. Our commitment to providing real views means that
                                                            your TikTok account remains safe and compliant with TikTok's
                                                            guidelines. We prioritize quality and authenticity in all
                                                            our services to help you achieve sustainable growth and
                                                            maximize your impact on TikTok.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            <div key="14"
                                                 className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4">
                                                <h4 className="text-base font-semibold"
                                                    id="accordion-collapse-heading-1">
                                                    <button type="button" onClick={() => toggleAccordion(14)}
                                                            className={`flex justify-between items-center p-5 w-full font-medium text-start ${activeIndex === 14 ? 'bg-gray-50 dark:bg-slate-800 text-indigo-600' : ''}`}
                                                            data-accordion-target="#accordion-collapse-body-1"
                                                            aria-expanded="true"
                                                            aria-controls="accordion-collapse-body-1">
                                                        <span>Does this take a long time to work?</span>
                                                        <FaArrowDown
                                                            className={`${activeIndex === 14 ? "rotate-180" : "rotate-270"} w-4 h-4 shrink-01`}></FaArrowDown>
                                                    </button>
                                                </h4>
                                                <div className={activeIndex === 14 ? "" : "hidden"}>
                                                    <div className="p-5">
                                                        <p className="text-slate-400 dark:text-gray-400">Buying TikTok
                                                            views from IG Champ is designed to be efficient and
                                                            effective. Once you purchase views, you can typically start
                                                            seeing results almost immediately. The exact timeframe may
                                                            vary depending on the package and current demand, but we aim
                                                            to deliver views promptly to enhance your TikTok presence
                                                            quickly. Rest assured, we prioritize fast and reliable
                                                            service to ensure you experience noticeable improvements in
                                                            your TikTok engagement without unnecessary delays.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            <div key="15"
                                                 className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4">
                                                <h4 className="text-base font-semibold"
                                                    id="accordion-collapse-heading-1">
                                                    <button type="button" onClick={() => toggleAccordion(15)}
                                                            className={`flex justify-between items-center p-5 w-full font-medium text-start ${activeIndex === 15 ? 'bg-gray-50 dark:bg-slate-800 text-indigo-600' : ''}`}
                                                            data-accordion-target="#accordion-collapse-body-1"
                                                            aria-expanded="true"
                                                            aria-controls="accordion-collapse-body-1">
                                                        <span>How does IG Champ ensure the confidentiality of my TikTok account information?</span>
                                                        <FaArrowDown
                                                            className={`${activeIndex === 15 ? "rotate-180" : "rotate-270"} w-4 h-4 shrink-01`}></FaArrowDown>
                                                    </button>
                                                </h4>
                                                <div className={activeIndex === 15 ? "" : "hidden"}>
                                                    <div className="p-5">
                                                        <p className="text-slate-400 dark:text-gray-400">IG Champ
                                                            prioritizes the confidentiality and security of your TikTok
                                                            account information through several measures: </p>
                                                        <p className="text-slate-400 dark:text-gray-400"><b>Secure
                                                            Transactions:</b> We use encrypted payment gateways to
                                                            protect your financial data during transactions. </p>
                                                        <p className="text-slate-400 dark:text-gray-400"><b>No Passwords
                                                            Required:</b> We never ask for your TikTok password when
                                                            purchasing views, ensuring your account remains secure. </p>
                                                        <p className="text-slate-400 dark:text-gray-400"><b>Privacy
                                                            Policy:</b> We adhere strictly to our privacy policy,
                                                            ensuring that your personal information is never shared with
                                                            third parties. </p>
                                                        <p className="text-slate-400 dark:text-gray-400"><b>Secure Data
                                                            Handling:</b> Our team follows industry best practices for
                                                            securely handling and storing customer data to prevent
                                                            unauthorized access. </p>
                                                        <p className="text-slate-400 dark:text-gray-400"><b>Customer
                                                            Support:</b> If you have any concerns about your account's
                                                            security, our customer support team is available to address
                                                            them promptly.</p>

                                                        <p className="text-slate-400 dark:text-gray-400">By implementing
                                                            these measures, IG Champ maintains a high standard of
                                                            confidentiality to safeguard your TikTok account information
                                                            throughout your experience with us.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            <div key="16"
                                                 className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4">
                                                <h4 className="text-base font-semibold"
                                                    id="accordion-collapse-heading-1">
                                                    <button type="button" onClick={() => toggleAccordion(16)}
                                                            className={`flex justify-between items-center p-5 w-full font-medium text-start ${activeIndex === 16 ? 'bg-gray-50 dark:bg-slate-800 text-indigo-600' : ''}`}
                                                            data-accordion-target="#accordion-collapse-body-1"
                                                            aria-expanded="true"
                                                            aria-controls="accordion-collapse-body-1">
                                                        <span>What are some tips for maximizing the impact of purchased TikTok views?</span>
                                                        <FaArrowDown
                                                            className={`${activeIndex === 16 ? "rotate-180" : "rotate-270"} w-4 h-4 shrink-01`}></FaArrowDown>
                                                    </button>
                                                </h4>
                                                <div className={activeIndex === 16 ? "" : "hidden"}>
                                                    <div className="p-5">
                                                        <p className="text-slate-400 dark:text-gray-400">To maximize the
                                                            impact of purchased TikTok views, consider these tips: </p>
                                                        <p className="text-slate-400 dark:text-gray-400"><b>Optimize
                                                            Video Content:</b> Ensure your videos are engaging,
                                                            high-quality, and relevant to your audience to encourage
                                                            further interaction beyond views.</p>
                                                        <p className="text-slate-400 dark:text-gray-400"><b>Use Hashtags
                                                            Wisely:</b> Incorporate popular and relevant hashtags to
                                                            increase discoverability and reach on TikTok.</p>
                                                        <p className="text-slate-400 dark:text-gray-400"><b>Encourage
                                                            Engagement:</b> Prompt viewers to like, comment, and share
                                                            your content to boost organic interaction and visibility.
                                                        </p>
                                                        <p className="text-slate-400 dark:text-gray-400"><b>Post
                                                            Consistently:</b> Maintain a regular posting schedule to
                                                            keep your audience engaged and attract new viewers
                                                            consistently. </p>
                                                        <p className="text-slate-400 dark:text-gray-400"><b>Interact
                                                            with Followers:</b> Respond to comments and engage with your
                                                            audience to foster a sense of community and loyalty.</p>
                                                        <p className="text-slate-400 dark:text-gray-400"><b>Utilize
                                                            TikTok Features:</b> Experiment with TikTok features like
                                                            duets, challenges, and trends to increase engagement and
                                                            exposure.</p>
                                                        <p className="text-slate-400 dark:text-gray-400"><b>Monitor
                                                            Analytics:</b> Use TikTok's analytics tools to track
                                                            performance metrics and optimize your strategy based on what
                                                            works best.</p>
                                                        <p className="text-slate-400 dark:text-gray-400">
                                                            <b>Cross-Promote:</b> Share your TikTok content on other
                                                            social media platforms to broaden your reach and attract new
                                                            viewers.</p>

                                                        <p className="text-slate-400 dark:text-gray-400">By combining
                                                            purchased views with these strategies, you can effectively
                                                            enhance your TikTok presence, increase engagement, and
                                                            achieve your social media goals more efficiently.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            <div key="17"
                                                 className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4">
                                                <h4 className="text-base font-semibold"
                                                    id="accordion-collapse-heading-1">
                                                    <button type="button" onClick={() => toggleAccordion(17)}
                                                            className={`flex justify-between items-center p-5 w-full font-medium text-start ${activeIndex === 17 ? 'bg-gray-50 dark:bg-slate-800 text-indigo-600' : ''}`}
                                                            data-accordion-target="#accordion-collapse-body-1"
                                                            aria-expanded="true"
                                                            aria-controls="accordion-collapse-body-1">
                                                        <span>How often can I purchase TikTok views?</span>
                                                        <FaArrowDown
                                                            className={`${activeIndex === 17 ? "rotate-180" : "rotate-270"} w-4 h-4 shrink-01`}></FaArrowDown>
                                                    </button>
                                                </h4>
                                                <div className={activeIndex === 17 ? "" : "hidden"}>
                                                    <div className="p-5">
                                                        <p className="text-slate-400 dark:text-gray-400">There isn't a
                                                            strict limit on how often you can purchase TikTok views, but
                                                            it's generally advisable to maintain a natural growth
                                                            pattern. Buying views occasionally to boost specific posts
                                                            or campaigns can be effective, but relying solely on
                                                            purchased views for continuous growth may not align with
                                                            TikTok's organic engagement principles.</p>
                                                        <p className="text-slate-400 dark:text-gray-400">It's essential
                                                            to balance purchased views with organic content creation,
                                                            engagement with your audience, and using TikTok's features
                                                            to build a genuine following over time. This approach helps
                                                            maintain authenticity and sustainability in your TikTok
                                                            strategy.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            <div key="18"
                                                 className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4">
                                                <h4 className="text-base font-semibold"
                                                    id="accordion-collapse-heading-1">
                                                    <button type="button" onClick={() => toggleAccordion(18)}
                                                            className={`flex justify-between items-center p-5 w-full font-medium text-start ${activeIndex === 18 ? 'bg-gray-50 dark:bg-slate-800 text-indigo-600' : ''}`}
                                                            data-accordion-target="#accordion-collapse-body-1"
                                                            aria-expanded="true"
                                                            aria-controls="accordion-collapse-body-1">
                                                        <span>Can buying TikTok views help increase my follower count?</span>
                                                        <FaArrowDown
                                                            className={`${activeIndex === 18 ? "rotate-180" : "rotate-270"} w-4 h-4 shrink-01`}></FaArrowDown>
                                                    </button>
                                                </h4>
                                                <div className={activeIndex === 18 ? "" : "hidden"}>
                                                    <div className="p-5">
                                                        <p className="text-slate-400 dark:text-gray-400">Buying TikTok
                                                            views can indirectly help increase your follower count by
                                                            boosting your content's visibility and engagement. When your
                                                            videos receive more views, they are more likely to appear on
                                                            the "For You" page and attract organic viewers who may
                                                            decide to follow your account if they find your content
                                                            engaging. </p>
                                                        <p className="text-slate-400 dark:text-gray-400">However, it's
                                                            important to note that while purchased views can enhance
                                                            initial visibility, sustained growth in followers typically
                                                            depends on consistently posting quality content, engaging
                                                            with your audience, and utilizing TikTok's features
                                                            effectively. Therefore, while buying TikTok views can be a
                                                            part of your growth strategy, it's beneficial to complement
                                                            it with a holistic approach to content creation and
                                                            engagement.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            <div key="19"
                                                 className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4">
                                                <h4 className="text-base font-semibold"
                                                    id="accordion-collapse-heading-1">
                                                    <button type="button" onClick={() => toggleAccordion(19)}
                                                            className={`flex justify-between items-center p-5 w-full font-medium text-start ${activeIndex === 19 ? 'bg-gray-50 dark:bg-slate-800 text-indigo-600' : ''}`}
                                                            data-accordion-target="#accordion-collapse-body-1"
                                                            aria-expanded="true"
                                                            aria-controls="accordion-collapse-body-1">
                                                        <span>How do I choose the right package of TikTok views?</span>
                                                        <FaArrowDown
                                                            className={`${activeIndex === 19 ? "rotate-180" : "rotate-270"} w-4 h-4 shrink-01`}></FaArrowDown>
                                                    </button>
                                                </h4>
                                                <div className={activeIndex === 19 ? "" : "hidden"}>
                                                    <div className="p-5">
                                                        <p className="text-slate-400 dark:text-gray-400">Choosing the
                                                            right package of TikTok views involves careful consideration
                                                            of your specific objectives and resources. Firstly, it's
                                                            crucial to define your goals for purchasing views—whether
                                                            you aim to increase visibility, enhance engagement, or boost
                                                            credibility. Next, evaluate your budgetary constraints to
                                                            determine how much you can comfortably invest in views. Once
                                                            you have a budget in mind, examine the details of each
                                                            package, including the number of views provided, delivery
                                                            timeframe, and any additional targeting options
                                                            available. </p>
                                                        <p className="text-slate-400 dark:text-gray-400">It's also
                                                            beneficial to read reviews or testimonials from other users
                                                            to gauge the effectiveness and reliability of the service
                                                            provider. Comparing packages across different providers can
                                                            help ensure you get the best value while adhering to
                                                            TikTok's guidelines and maintaining the authenticity of your
                                                            account. By taking these steps, you can confidently choose a
                                                            TikTok views package that aligns with your goals and
                                                            supports your social media strategy effectively. </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            <div key="20"
                                                 className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4">
                                                <h4 className="text-base font-semibold"
                                                    id="accordion-collapse-heading-1">
                                                    <button type="button" onClick={() => toggleAccordion(20)}
                                                            className={`flex justify-between items-center p-5 w-full font-medium text-start ${activeIndex === 20 ? 'bg-gray-50 dark:bg-slate-800 text-indigo-600' : ''}`}
                                                            data-accordion-target="#accordion-collapse-body-1"
                                                            aria-expanded="true"
                                                            aria-controls="accordion-collapse-body-1">
                                                        <span>What should I do if I have issues with the likes I purchased for TikTok?</span>
                                                        <FaArrowDown
                                                            className={`${activeIndex === 20 ? "rotate-180" : "rotate-270"} w-4 h-4 shrink-01`}></FaArrowDown>
                                                    </button>
                                                </h4>
                                                <div className={activeIndex === 20 ? "" : "hidden"}>
                                                    <div className="p-5">
                                                        <p className="text-slate-400 dark:text-gray-400">If you
                                                            encounter any issues with the likes you purchased for your
                                                            TikTok videos from IG Champ, it’s important to promptly
                                                            contact our customer support team for assistance. Provide
                                                            them with specific details, such as your order number and
                                                            TikTok username, along with a clear description of the
                                                            problem. </p>
                                                    </div>
                                                    <div className="p-5">
                                                        <p className="text-slate-400 dark:text-gray-400">IG Champ’s
                                                            support team is dedicated to resolving issues efficiently
                                                            and will guide you through the necessary steps to address
                                                            the issue. Be prepared to follow their instructions and
                                                            provide any additional information they request to
                                                            facilitate a quick resolution. They are committed to
                                                            ensuring your satisfaction with their services and may offer
                                                            replacements or refunds if necessary. Rest assured that IG
                                                            Champ values customer feedback and strives to provide
                                                            reliable support to resolve any issues you may encounter
                                                            with your purchased likes on TikTok.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer/>
        </>
    )
}



import client1 from '../assets/images/client/01.jpg';
import client2 from '../assets/images/client/02.jpg';
import client3 from '../assets/images/client/03.jpg';
import client4 from '../assets/images/client/04.jpg';
import client5 from '../assets/images/client/05.jpg';
import client6 from '../assets/images/client/06.jpg';



export const footerLinks = [
    {
        liClass: '',
        route: '/buy-instagram-followers',
        title: 'Buy Instagram Followers',

    },
    {
        liClass: 'mt-[10px]',
        route: '/buy-instagram-likes',
        title: 'Buy Instagram Likes',

    },
    {
        route: '/buy-instagram-views',
        title: 'Buy Instagram Views',
        liClass: 'mt-[10px]',
    },
    {
        route: '/buy-tiktok-likes',
        title: 'Buy Tiktok Likes',
        liClass: 'mt-[10px]',
    },
    {
        route: '/buy-tiktok-followers',
        title: 'Buy Tiktok Followers',
        liClass: 'mt-[10px]',
    },
    {
        route: '/buy-tiktok-views',
        title: 'buy Tiktok Views',
        liClass: 'mt-[10px]',
    }
];
export const footerCompany = [
    {
        liClass: '',
        route: '/about-us',
        title: 'About us',
    },
    {
        liClass: 'mt-[10px]',
        route: '/term-of-service',
        title: 'Term of Service',

    },
    {
        route: '/privacy-policy',
        title: 'Privacy Policy',
        liClass: 'mt-[10px]',

    },
    {
        route: '/blog',
        title: 'Blog',
        liClass: 'mt-[10px]',

    },
    {
        route: '/contact',
        title: 'Contact',
        liClass: 'mt-[10px]',

    }
]


export const feedback =[
    {
        description: '"I\'ve seen tremendous growth in my follower count since using IG Champ. The service is reliable, and the results are real. Highly recommended!"',
        image: client1,
        name: 'Sarah, Sydney, AU',
        role: 'Customer'
    },
    {
        description: `"IG Champ has completely transformed my Instagram engagement. The increase in likes and views has been incredible. Their team really knows what they're doing."`,
        image: client2,
        name: 'James, Manchester, UK',
        role: 'Customer'
    },
    {
        description: '"Fantastic service! IG Champ helped me reach a broader audience and significantly boosted my profile. I couldn\'t be happier with the results."',
        image: client3,
        name: 'Emily, Vancouver, CA',
        role: 'Customer'
    },
    {
        description: '"I was skeptical at first, but IG Champ delivered exactly what they promised. My follower count and engagement rates have soared. Great value for money."',
        image: client4,
        name: 'Oliver, NY, USA',
        role: 'Customer'
    },
    {
        description: '"IG Champ\'s targeted approach has made a noticeable difference in my Instagram presence. My posts are getting more attention, and my follower base is growing steadily."',
        image: client5,
        name: 'Charlotte, Milan, IT',
        role: 'Customer'
    },
    {
        description: '"Since I started using IG Champ, my Instagram account has gained a lot of traction. The service is efficient, and the support team is always helpful. A top-notch experience!"',
        image: client6,
        name: 'Daniel, Munich, Germany',
        role: 'Customer'
    }
]




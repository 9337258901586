import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {Helmet} from "react-helmet";

import '../../../node_modules/tiny-slider/dist/tiny-slider.css'

import Navbar from '../../component/Navbar/navbar';
import Footer from '../../component/Footer/footer';

import GetInTuch from '../../component/getInTuch';

import * as Icon from 'react-feather';
import {
    FaUser,
    FaThumbsUp,
    BsCheckCircle,
    BiTrendingUp, FaArrowRight, MdKeyboardArrowRight
} from '../../assets/icons/icons'

import classic02 from "../../assets/images/igchamp/instagram-growth ig-champ.webp";
import classic03 from "../../assets/images/igchamp/instagram-growth ig-champ1.webp";
import CountUp from "react-countup";

import {
    accordionData,
    categories,
    constructionAbout, consultingAbout,
    partnerImage,
    pricingTableData
} from "../../data/dataTwo";

import UserFeedBack2 from "../../component/userFeedBack2";
import client1 from "../../assets/images/client/01.jpg";
export default function Index() {
    useEffect(() => {
        document.documentElement.setAttribute("dir", "ltr");
        document.documentElement.classList.add('light');
    }, []);
    const [pricingTable, setpricingTable] = useState(pricingTableData.filter((element) => {
        return element.cat === 10;
    }))

    const [activeIndex, setActiveIndex] = useState(1);
    const [isOpenTab, setisOpenTab] = useState(1);
    const [isOpenSubCat, setisOpenSubCat] = useState(10);
    let selectedCategory = categories.find((element) => {
        return element.id === 1;
    });
    const [subcate, setSubcate] = useState(selectedCategory.sub);
    useEffect(() => {
        setpricingTable(
            pricingTableData.filter((element) => {
                return element.cat === isOpenSubCat;
            })
        );
    }, [isOpenSubCat]);
    const handleTabClick = (id) => {
        setisOpenTab(id);
        setisOpenSubCat(id*10);
        selectedCategory = categories.find((element, index) => {
            return element.id === id;
        })
        setSubcate(selectedCategory.sub)
    };
    const handleSubCat = (id) => {
        setisOpenSubCat(id);

        };

    const toggleAccordion = (index) => {
        if (activeIndex === index) {
            setActiveIndex(0);
        } else {
            setActiveIndex(index);
        }
    }

    const accordionData1 = accordionData.filter((x) => x.id < 5);
    const accordionData2 = accordionData.filter((x) => x.id > 4);

    return (
        <div>
            <Helmet>
                <title>IgChamp Instagram Growth services - Buy instant followers, likes and views</title>
                <link rel="canonical" href="https://igchamp.com"/>
                <meta name="description"
                      content="Checkout Igchamp Instagram growth services with customized packages. Get real followers, likes and views at cheap prices with Instant delivery"/>
                <meta property="og:title"
                      content="IgChamp Instagram Growth services - Buy instant followers, likes and views"/>
                <meta property="og:description"
                      content="Checkout Igchamp Instagram growth services with customized packages. Get real followers, likes and views at cheap prices with Instant delivery"/>
                <meta property="og:image" content={classic02}/>
                <meta property="og:url" content="https://igchamp.com"/>
            </Helmet>
            <Navbar/>
            <section className="relative overflow-hidden md:pt-24 pt-28">
                <div className="container relative">
                    <div className="grid md:grid-cols-2 grid-cols-1 items-center mt-8 gap-[30px] relative">
                        <div className="md:me-6">
                            <ul className="list-none mb-0 text-amber-400 text-xl space-x-1">
                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                <li className="inline"><i className="mdi mdi-star"></i></li>
                            </ul>
                            <h1 className="font-bold lg:leading-normal leading-normal text-3xl lg:text-4xl mb-5 text-black dark:text-white">Grow
                                Your Instagram Presence with <span
                                    className="after:absolute after:end-0 after:start-0 after:bottom-1 after:-rotate-3 after:h-2 after:w-auto after:rounded-md after:bg-indigo-600/20 relative text-indigo-600">IGChamp</span>
                            </h1>
                            <p className="text-slate-400 text-lg max-w-xl">Are you ready to skyrocket your Instagram
                                influence? At IGChamp, we specialize in providing superior Instagram growth services to
                                enhance your Instagram growth, engagement, and overall success.
                            </p>

                            <div className="mt-6">
                                <Link to="/buy-instagram-followers"
                                      className="py-2 px-5 inline-flex items-center font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md me-2 mt-2"><FaUser
                                    className="me-2 text-sm "/> Buy Followers</Link>
                                <Link to="/buy-instagram-likes"
                                      className="py-2 px-5 inline-flex items-center font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md me-2 mt-2"><FaThumbsUp
                                    className="me-2 text-sm "/> Buy Likes</Link>
                                <Link to="/buy-instagram-views"
                                      className="py-2 px-5 inline-flex items-center font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md me-2 mt-2"><FaThumbsUp
                                    className="me-2 text-sm "/> Buy Views</Link>
                            </div>
                        </div>

                        <div className="relative">
                            <img src={classic02} className="mx-auto " alt="Instagram Growth services"/>
                            <div
                                className="overflow-hidden after:content-[''] after:absolute after:h-10 after:w-10 after:bg-indigo-600/20 after:top-0 after:start-0 after:-z-1 after:rounded-lg after:animate-[spin_10s_linear_infinite]"></div>


                        </div>

                        <div className="relative animate-[spin_30s_linear_infinite] -z-1">
                <span
                    className="after:absolute after:start-0 after:bottom-1/2 after:translate-y-1/2 after:h-2 after:w-8 after:rounded-md after:bg-indigo-600/20 relative after:z-10"></span>
                            <span
                                className="after:absolute after:start-0 after:bottom-1/2 after:translate-y-1/2 after:rotate-90 after:h-2 after:w-8 after:rounded-md after:bg-indigo-600/20 relative after:z-10"></span>
                        </div>
                    </div>
                </div>

                {/*<div className="container relative md:mt-24 mt-16">
                    <div className="grid md:grid-cols-6 grid-cols-2 justify-center gap-[30px]">
                        {partnerImage.map((item, index) => {
                            return (
                                <div className="mx-auto py-4" key={index}>
                                    <img src={item} className="h-20" alt=""/>
                                </div>
                            )
                        })}
                    </div>
                </div>*/}
            </section>

            <section className="relative md:py-24 py-16">
                <div className="container relative">
                    <div className="grid grid-cols-1 pb-8 text-center">
                        <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">Choose
                            Pricing
                            Plan</h3>

                        <p className="text-slate-400 max-w-xl mx-auto">Your account should be public!</p>
                    </div>

                    <div className="grid grid-cols-1">
                        <ul className="inline-block w-fit mx-auto flex-wrap justify-center text-center p-2 bg-white dark:bg-slate-900 shadow dark:shadow-gray-800 rounded-full">
                            {categories.map((item, index) => {
                                return (
                                    <li role="presentation" className="inline-block" key={item.id}>
                                        <button onClick={() => handleTabClick(item.id)}
                                                className={`${isOpenTab === item.id ? 'text-white bg-indigo-600 hover:text-white' : ''} px-4 py-1 text-sm font-semibold rounded-full w-full transition-all duration-500 ease-in-out`}>{item.name}
                                        </button>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                    <div className="grid grid-cols-1 mt-6">
                        <ul className="inline-block w-fit mx-auto flex-wrap justify-center text-center p-2 bg-white dark:bg-slate-900 shadow dark:shadow-gray-800 rounded-full">

                            {subcate.map((subc, index) => {
                                return (
                                    <li role="presentation" className="inline-block" key={subc.id}>
                                        <button onClick={() => handleSubCat(subc.id)}
                                                className={`${isOpenSubCat === subc.id ? 'text-white bg-indigo-600 hover:text-white' : ''} px-4 py-1 text-sm font-semibold rounded-full w-full transition-all duration-500 ease-in-out`}>{subc.name}
                                        </button>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                    <div id="StarterContent" className="mt-6">
                        <div className="grid lg:grid-cols-4 md:grid-cols-2 mt-8 gap-[30px]">
                            {pricingTable.map((pricing, index) => {
                                return (
                                    <div
                                        className="group border-b-[3px] dark:border-gray-700 p-6 py-8 hover:border-indigo-600 dark:hover:border-indigo-600 transition-all duration-500 ease-in-out hover:scale-105 relative overflow-hidden shadow dark:shadow-gray-800 rounded-md bg-gray-50 dark:bg-slate-800 hover:bg-white dark:hover:bg-slate-900 h-fit">
                                        <h6 className="font-bold uppercase mb-5 text-indigo-600">{pricing.name}</h6>

                                        <div className="flex mb-5">
                                            <span className="text-xl font-semibold">$</span>
                                            <span className="price text-4xl font-semibold mb-0">{pricing.price}</span>
                                            <span className="text-xl font-semibold self-end mb-1"></span>
                                        </div>

                                        <ul className="list-none text-slate-400">
                                            <li className="mb-1 flex items-center" key={"a" + pricing.id}>
                                                <BsCheckCircle className="text-indigo-600 text-base me-2"/> 5 Minutes to
                                                24Hours
                                            </li>
                                            <li className="mb-1 flex items-center" key={"b" + pricing.id}>
                                                <BsCheckCircle className="text-indigo-600 text-base me-2"/> Safe &
                                                Secure
                                            </li>
                                            <li className="mb-1 flex items-center" key={"c" + pricing.id}>
                                                <BsCheckCircle className="text-indigo-600 text-base me-2"/> Real and
                                                Active
                                            </li>
                                            <li className="mb-1 flex items-center" key={"d" + pricing.id}>
                                                <BsCheckCircle className="text-indigo-600 text-base me-2"/> Money-Back
                                                Guarantee
                                            </li>
                                        </ul>
                                        <a
                                            className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md mt-5" href={pricing.link}>Buy
                                            Now</a>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </section>

            <section
                className="w-full table relative ">

                <div className="container relative mt-16">
                    <div className="grid grid-cols-1 pb-8 text-center">
                        <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">Why
                            Choose IGChamp?</h3>
                    </div>
                    <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-[24px]">
                        {consultingAbout.map((item, index) => {
                            let Icons = item.icon
                            return (
                                <div key={index}
                                     className="group border-b-[3px] dark:border-gray-700 p-6 py-8 hover:border-indigo-600 dark:hover:border-indigo-600 transition-all duration-500 ease-in-out hover:scale-105 relative overflow-hidden shadow dark:shadow-gray-800 rounded-md bg-gray-50 dark:bg-slate-800 hover:bg-white dark:hover:bg-slate-900 h-fit">
                                    <Icons className="h-10 w-10 stroke-1 text-indigo-600"/>

                                    <div className="content mt-6">
                                        <Link to="/page-services"
                                              className="title h5 text-xl font-semibold hover:text-indigo-600">{item.title}</Link>
                                        <p className="text-slate-400 mt-4">{item.desc}</p>

                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </section>
            <section className="relative overflow-hidden pb-32">

                <div className="grid md:grid-cols-2 grid-cols-1 items-center mt-16 gap-[30px]">
                    <div className="relative wow animate__animated animate__fadeInLeft" data-wow-delay=".3s">
                        <img src={classic03} className="mx-auto w-1/2" alt="IGChamp Instagram Growth Servicse"/>
                        <div
                            className="overflow-hidden absolute lg:h-[400px] h-[320px] lg:w-[400px] w-[320px] bg-indigo-600/5 bottom-0 start-0 rotate-45 -z-1 rounded-3xl"></div>
                    </div>

                    <div className="lg:ms-8 wow animate__animated animate__fadeInRight" data-wow-delay=".3s">
                        <h3 className="mb-4 text-2xl leading-normal font-medium">Start Boosting Your Instagram Today
                            with IGChamp</h3>
                        <p className="text-slate-400">It's time to start boosting your Instagram presence today and see
                            real, tangible results. Our comprehensive range of services ensures that every aspect of
                            your Instagram account is optimized for success. Our team of experts uses proven,
                            data-driven techniques to attract genuine followers, increase your engagement rates, and
                            build a loyal audience around your Instagram presence.</p>
                        <ul className="list-none text-slate-400 mt-4">
                            <li className="mb-1 flex items-center"><BsCheckCircle
                                className="text-indigo-600 text-base me-2"/> Real Followers
                            </li>
                            <li className="mb-1 flex items-center"><BsCheckCircle
                                className="text-indigo-600 text-base me-2"/> Affordable Prices
                            </li>
                            <li className="mb-1 flex items-center"><BsCheckCircle
                                className="text-indigo-600 text-base me-2"/> Organic Growth
                            </li>
                            <li className="mb-1 flex items-center"><BsCheckCircle
                                className="text-indigo-600 text-base me-2"/> 24/7 Customer Support
                            </li>
                        </ul>

                        <div className="mt-4">
                            <Link to="/buy-instagram-followers"
                                  className="relative inline-flex items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500">
                                Click here to boost you Instagram
                                <MdKeyboardArrowRight className="text-xl ms-1"/></Link>
                        </div>
                    </div>
                </div>
            </section>
            {/*<section
                className="w-full table relative bg-indigo-600">
                <div className="absolute inset-0 bg-rose-400"></div>
                <div className="container-fluid relative overflow-hidden">
                    <div className="grid grid-cols-1 mt-8">
                        <div
                            className="slider relative overflow-hidden m-auto mb-4 before:content-[''] before:absolute before:top-0 before:start-0 before:z-2 after:content-[''] after:absolute after:top-0 after:end-0 after:z-2">
                            <div className="slide-track flex items-center">
                                <div className="slide h-auto  mb-12 w-28">
                                    <img src={client1} className="h-24 w-24 rounded-full shadow dark:shadow-gray-800"
                                         alt=""/>
                                </div>
                                <div className="slide h-auto  mt-12 w-28">
                                    <img src={client1} className="h-24 w-24 rounded-full shadow dark:shadow-gray-800"
                                         alt=""/>
                                </div>
                                <div className="slide h-auto  mb-12 w-28">
                                    <img src={client1} className="h-24 w-24 rounded-full shadow dark:shadow-gray-800"
                                         alt=""/>
                                </div>
                                <div className="slide h-auto  mt-12 w-28">
                                    <img src={client1} className="h-24 w-24 rounded-full shadow dark:shadow-gray-800"
                                         alt=""/>
                                </div>
                                <div className="slide h-auto  mb-12 w-28">
                                    <img src={client1} className="h-24 w-24 rounded-full shadow dark:shadow-gray-800"
                                         alt=""/>
                                </div>
                                <div className="slide h-auto  mt-12 w-28">
                                <img src={client1} className="h-24 w-24 rounded-full shadow dark:shadow-gray-800"
                                         alt=""/>
                                </div>
                                <div className="slide h-auto  mb-12 w-28">
                                    <img src={client1} className="h-24 w-24 rounded-full shadow dark:shadow-gray-800"
                                         alt=""/>
                                </div>
                                <div className="slide h-auto  mt-12 w-28">
                                    <img src={client1} className="h-24 w-24 rounded-full shadow dark:shadow-gray-800"
                                         alt=""/>
                                </div>
                                <div className="slide h-auto  mb-12 w-28">
                                    <img src={client1} className="h-24 w-24 rounded-full shadow dark:shadow-gray-800"
                                         alt=""/>
                                </div>
                                <div className="slide h-auto  mt-12 w-28">
                                    <img src={client1} className="h-24 w-24 rounded-full shadow dark:shadow-gray-800"
                                         alt=""/>
                                </div>
                                <div className="slide h-auto  mb-12 w-28">
                                    <img src={client1} className="h-24 w-24 rounded-full shadow dark:shadow-gray-800"
                                         alt=""/>
                                </div>
                                <div className="slide h-auto  mt-12 w-28">
                                    <img src={client1} className="h-24 w-24 rounded-full shadow dark:shadow-gray-800"
                                         alt=""/>
                                </div>
                                <div className="slide h-auto  mb-12 w-28">
                                    <img src={client1} className="h-24 w-24 rounded-full shadow dark:shadow-gray-800"
                                         alt=""/>
                                </div>
                                <div className="slide h-auto  mt-12 w-28">
                                    <img src={client1} className="h-24 w-24 rounded-full shadow dark:shadow-gray-800"
                                         alt=""/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>*/}

            <section className="container relative md:mt-24 mt-16">
                <div className="grid grid-cols-1 justify-center">
                    <div className="">
                        <div
                            className="relative bg-white dark:bg-slate-900 shadow dark:shadow-gray-800 rounded-md overflow-hidden">
                            <div className="grid lg:grid-cols-12 grid-cols-1">
                                <div className="lg:col-span-4 order-1 lg:order-2 bg-indigo-600">
                                    <div className="p-[30px] lg:text-start text-center">
                                        <span className="text-xl text-white/75">Order Placement</span>
                                        <h4 className="text-2xl font-semibold text-white my-3">How to Buy from
                                            IGChamp?</h4>
                                        <p className="text-white/75 max-w-xl mx-auto">IGChamp offers a number of
                                            Instagram services, including followers, likes, and views for users
                                            globally. If you’re interested in ordering any of these services from our
                                            website, here are the basic steps you need to take:</p>
                                        {/*<div className="mt-6">
                          <Link to="#"
                                className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle transition duration-500 ease-in-out text-base text-center bg-green-600 hover:bg-green-700 border-green-600 hover:border-green-700 text-white rounded-md">About
                            us</Link>
                        </div>*/}
                                    </div>
                                </div>

                                <div className="lg:col-span-8 order-2 lg:order-1">
                                    <div className="grid md:grid-cols-2 grid-cols-1 p-[30px] gap-[30px]">
                                        {constructionAbout.map((item, index) => {
                                            let Icons = item.icon
                                            return (
                                                <div className="group flex transition-all duration-500 ease-in-out"
                                                     key={index}>
                                                    <div
                                                        className="flex align-middle justify-center items-center w-14 h-14 mt-1 bg-indigo-600/5 group-hover:bg-indigo-600 group-hover:text-white text-indigo-600 rounded-full text-2xl shadow-sm dark:shadow-gray-800 transition-all duration-500 ease-in-out">
                                                        <Icons className='w-8 h-8'/>
                                                    </div>
                                                    <div className="flex-1 ms-4">
                                                        <h4 className="mb-0 text-lg font-medium">{item.title}</h4>
                                                        <p className="text-slate-400 mt-3">{item.desc}</p>
                                                    </div>
                                                </div>
                                            )
                                        })}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
            <section className="relative md:py-8 py-4">


                <div className="container relative md:mt-24 mt-16">
                    <div className="grid grid-cols-1 pb-8 text-center">
                        <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">What
                            Our Clients Are Saying About IGChamp</h3>
                    </div>

                    <UserFeedBack2/>
                </div>


            </section>


            <section className="relative md:py-24 py-16 bg-gray-50 dark:bg-slate-800">


                <GetInTuch title={false}/>
            </section>
            <div className="relative">
                <div
                    className="shape absolute sm:-bottom-px -bottom-[2px] start-0 end-0 overflow-hidden z-1 text-dark-footer">
                    <svg className="w-full h-auto scale-[2.0] origin-top" viewBox="0 0 2880 250" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path d="M720 125L2160 0H2880V250H0V125H720Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>

            <Footer/>

        </div>
    )
}

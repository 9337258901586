import React from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import "./assets/libs/@mdi/font/css/materialdesignicons.min.css";
import Index from "./pages/index";
import IgFollowers from "./pages/instagram/Followers";
import IgLikes from "./pages/instagram/Likes";
import IgViews from "./pages/instagram/Views";
import TtFollowers from "./pages/tiktok/Followers";
import TtLikes from "./pages/tiktok/Likes";
import TtViews from "./pages/tiktok/Views";/*
import YtSubscribers from "./pages/youtube/Subscribers";
import YtLikes from "./pages/youtube/Likes";
import YtViews from "./pages/youtube/Views";
import YtWatchTime from "./pages/youtube/WatchTime";*/
import About from "./pages/About";
import TermOfService from "./pages/TermOfService";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Switch from "./component/Switch";


export default function App() {
  return (
    <BrowserRouter>
      <Switch />
      <Routes>
        <Route path="/index" element={<Navigate to="/" />} />
        <Route exact path="/" element={<Index />} />
        <Route exact path="/about" element={<About />} />
        <Route exact path="/term-of-service" element={<TermOfService />} />
        <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />

        <Route exact path="/buy-instagram-followers" element={<IgFollowers />} />
        <Route exact path="/buy-instagram-likes" element={<IgLikes />} />
        <Route exact path="/buy-instagram-views" element={<IgViews />} />

        <Route exact path="/buy-tiktok-followers" element={<TtFollowers />} />
        <Route exact path="/buy-tiktok-likes" element={<TtLikes />} />
        <Route exact path="/buy-tiktok-views" element={<TtViews />} />

        {/*<Route exact path="/buy-youtube-subscriber" element={<YtSubscribers />} />
        <Route exact path="/buy-youtube-likes" element={<YtLikes />} />
        <Route exact path="/buy-youtube-views" element={<YtViews />} />
        <Route exact path="/buy-youtube-watch-time" element={<YtWatchTime />} />*/}
      </Routes>
    </BrowserRouter>
  );
}
